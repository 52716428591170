<template>
  <div id="location" class="lg:pb-12 lg:pt-6" :class="isContactPage ? '' : 'container'">
    <CommonHeading :level="2" class="text-2xl text-primary-1000 text-center my-8" :class="isContactPage && 'hidden'">
      {{ t('footer.location') }}
    </CommonHeading>

    <!-- Desktop Locations -->
    <div
      class="hidden mx-auto mb-12 lg:grid gap-4"
      :class="isContactPage ? 'lg:grid-cols-1' : 'lg:grid-cols-1 xl:grid-cols-2'"
    >
      <HeadlessTabGroup>
        <HeadlessTabList class="flex flex-col gap-x-4 lg:flex-row">
          <HeadlessTab
            class="bg-primary-100"
            v-for="(location, index) in locations"
            as="template"
            :key="location.id"
            v-slot="{ selected }"
          >
            <div
              class="relative group outline-none cursor-pointer w-full lg:h-64 xl:h-full"
              :class="selected ? 'shadow ring-4 ring-offset-1 ring-primary-400 rounded-lg' : ''"
            >
              <img
                :src="location.image"
                class="relative w-full h-full object-cover rounded-lg"
                :class="index === 1 ? 'object-center' : 'object-left'"
              />
              <div
                class="absolute inset-0 z-10 rounded-lg transition-all"
                :class="
                  selected
                    ? 'bg-gradient-to-b from-transparent to-black/50 h-1/2 mt-auto'
                    : 'bg-primary-100/90 opacity-100'
                "
              ></div>
              <div
                class="absolute inset-0 z-10 flex flex-col justify-end items-center text-2xl py-4"
                :class="selected ? 'text-white font-bold' : 'text-primary-900 font-medium'"
              >
                {{ location.name }}
                <span class="text-sm flex items-center leading-3">
                  <Icon name="tabler:clock" size="18" class="mr-1" />{{ location.opening_hours }}
                </span>
                <span class="text-sm"> {{ location.closed ? `${t('footer.closed')}: ${location.closed}` : '' }} </span>
              </div>
            </div>
          </HeadlessTab>
        </HeadlessTabList>

        <HeadlessTabPanels class="mt-2">
          <HeadlessTabPanel v-for="(location, idx) in Object.values(locations)" :key="idx" class="rounded-lg shadow-md">
            <iframe
              class="w-full h-96 rounded-lg"
              frameborder="0"
              style="border: 0"
              referrerpolicy="no-referrer-when-downgrade"
              :src="location.src"
              allowfullscreen
            >
            </iframe>
          </HeadlessTabPanel>
        </HeadlessTabPanels>
      </HeadlessTabGroup>
    </div>

    <!-- Mobile Locations -->
    <div class="flex flex-col justify-center items-center w-full px-4 gap-4 mt-4 md:flex-row lg:hidden">
      <a v-for="location in locations" :href="location.maps_url" target="_blank">
        <div class="flex flex-col w-full text-center items-center">
          <img :src="location.image" class="w-44 h-44 bg-white rounded-lg object-cover border" />
          <div class="font-dinpro text-primary-1000 font-semibold mt-2 px-2">{{ location.name }}</div>
          <div class="font-dinpro text-primary-1000 font-light px-2 leading-4 mb-2">{{ location.place }}</div>
          <div class="font-dinpro text-primary-1000 font-light px-2 leading-4 mb-2">
            <Icon name="tabler:clock" size="18" class="mr-1" />{{ location.opening_hours }}
          </div>
          <div class="font-dinpro text-primary-1000 font-light px-2 leading-4 mb-2">
            {{ location.closed ? `${t('footer.closed')}: ${location.closed}` : '' }}
          </div>
          <div class="text-primary-1000 px-2 uppercase text-xs font-bold">
            {{ t('footer.locations.get_directions') }}
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import ms_sante from '~/assets/images/ms_clinic.webp';
import ms_boutique from '~/assets/images/ms_boutique.jpg';

defineProps({
  isContactPage: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const config = useRuntimeConfig();

const locations = ref([
  {
    id: 1,
    name: t('footer.locations.first_location'),
    src: `https://www.google.com/maps/embed/v1/place?q=Maison%20Sante%20&key=${config.public.GOOGLE_MAPS_API_KEY}`,
    maps_url: 'https://maps.app.goo.gl/cS8unxheWsoiR52F9',
    image: ms_sante,
    place: t('footer.locations.first_place'),
    opening_hours: t('footer.locations.first_opening_hours'),
    closed: t('footer.locations.first_closed'),
  },
  {
    id: 2,
    name: t('footer.locations.second_location'),
    src: `https://www.google.com/maps/embed/v1/place?q=Maison%20Sante%20%Boutique&key=${config.public.GOOGLE_MAPS_API_KEY}`,
    maps_url: 'https://maps.app.goo.gl/KDaCaf9y2gk6vS546',
    image: ms_boutique,
    place: t('footer.locations.second_place'),
    opening_hours: t('footer.locations.second_opening_hours'),
    closed: '',
  },
]);
</script>

<style scoped>
.button-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

button:hover .button-background::before {
  opacity: 0.2;
}

button .button-background::before {
  opacity: 1;
}

button.text-primary-100.shadow .button-background::before {
  background-color: rgba(0, 0, 0, 0.5); /* Overlay for selected button */
}
</style>
